import { NavItem } from '../../components/MenuNav/types/NavItem';
import { ApplicationRoutes } from '../../constants/appRoutes';

export const AdminNavItems: NavItem = {
  text: 'Admin',
  subItems: [{ text: 'Users', path: ApplicationRoutes.admin.users }]
};

export const CrmNavItems: NavItem = {
  text: 'CRM',
  subItems: [
    { text: 'Clients', path: ApplicationRoutes.crm.clients },
    { text: 'Properties', path: ApplicationRoutes.crm.properties }
  ]
};

export const UserNavItems: NavItem[] = [
  { text: 'Manage Account', path: ApplicationRoutes.account.myProfile },
  { text: 'Logout', path: ApplicationRoutes.account.logout }
];

export const CleaningAdminItems: NavItem = {
  text: 'Cleaning Admin',
  subItems: [
    { text: 'Checklists', path: ApplicationRoutes.cleaningAdmin.checklists },
    { text: 'Packages & Inventory', path: ApplicationRoutes.cleaningAdmin.cleaningPackages }
  ]
};

export const SchedulingItems: NavItem = {
  text: 'Scheduling',
  subItems: [
    { text: 'Calendar', path: ApplicationRoutes.scheduling.calendar },
    { text: 'Alerts', path: ApplicationRoutes.scheduling.alerts }
  ]
};

export const UnauthenticatedNavItems: NavItem = {
  text: 'Login',
  path: ApplicationRoutes.account.login
};
