import { ApplicationRoutes } from '../constants/appRoutes';

export const CleaningRoutes = [
  {
    path: ApplicationRoutes.cleaning.startCleaning,
    async lazy() {
      const component = await import('../features/cleaning/dashboard/StartCleaning');
      return { Component: component.StartCleaning };
    }
  },
  {
    path: `/cleaning/cleaning-checklist/:type/:id`,
    async lazy() {
      const component = await import('../features/cleaning/checklist/CleaningChecklist');
      return { Component: component.CleaningChecklist };
    }
  }
];
